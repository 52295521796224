export enum PERMISSION {
  EDIT_STAFF = "Edit Staff",
  EDIT_LINKS = "Edit Links",
}

export const PERMISSIONS: Array<PERMISSION> = [PERMISSION.EDIT_STAFF, PERMISSION.EDIT_LINKS];

export enum SettingsSections {
  PERIODS = "Narrative Periods",
  SECTIONS = "Narrative Sections",
  OBJECTIVES = "Narrative Objectives",
  OVERRIDE = "Roster Override",
}

export enum COLLECTION {
  daysPerWeek = "daysPerWeek",
  incidentTypes = "incidentTypes",
  reports = "reports",
  staff = "staff",
}

export const COLLECTIONS = Object.values(COLLECTION);

export const BLANK_LINK_FORM = {
  link: "",
  name: "",
  sectionId: "",
};

export const BLANK_SECTION_FORM = {
  order: 0,
  name: "",
};
