import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginContainer from "./components/Login/LoginContainer";
import CustomRoute from "./CustomRoute";
import StaffHubPage from "./pages/StaffHubPage";
import StaffPage from "./pages/StaffPage";

export const routes = (
  <Routes>
    <Route path="/login" element={<LoginContainer />} />
    <Route
      path="/"
      element={
        <CustomRoute>
          <StaffHubPage />
        </CustomRoute>
      }
    />
    <Route
      path="/staff"
      element={
        <CustomRoute>
          <StaffPage />
        </CustomRoute>
      }
    />
  </Routes>
);
