import { useState, useEffect } from "react";
import { Box, Divider, Typography, Grid } from "@mui/material";
import { SectionRecord, LinkRecord } from "../../interfaces/interfaces";
import { linksAtom, loggedInStaffAtom, sectionFormAtom } from "../../recoil/atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import LinkCard from "./LinkCard";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { PERMISSION } from "../../libraries/objects";

type Props = {
  section: SectionRecord;
  setLinkOpen: (prop: boolean) => void;
  setSectionOpen: (prop: boolean) => void;
  setLinkToDeleteId: (prop: string | null) => void;
  setDeleteLinkOpen: (prop: boolean) => void;
  setDeleteSectionOpen: (prop: boolean) => void;
  setSectionToDeleteId: (prop: string | null) => void;
};

function Section({
  section,
  setLinkOpen,
  setSectionOpen,
  setLinkToDeleteId,
  setDeleteLinkOpen,
  setDeleteSectionOpen,
  setSectionToDeleteId,
}: Props) {
  const [links, setLinks] = useState<LinkRecord[]>([]);
  const [showIcons, setShowIcons] = useState(false);
  const linksRecords = useRecoilValue(linksAtom);
  const setSectionForm = useSetRecoilState(sectionFormAtom);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);

  const handleMouseEnter = () => {
    setShowIcons(true);
  };

  const handleMouseLeave = () => {
    setShowIcons(false);
  };

  const handleEditClick = () => {
    setSectionForm(section);
    setSectionOpen(true);
  };

  const handleDeleteClick = () => {
    setSectionToDeleteId(section.id);
    setDeleteSectionOpen(true);
  };

  useEffect(() => {
    if (!linksRecords) return;
    const currentLinks = linksRecords.filter((link) => link.sectionId === section.id);
    setLinks(currentLinks);
  }, [section, linksRecords]);
  return (
    <>
      <Box sx={{ mt: 1 }}>
        <Box
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Typography variant="h6">{section.name}</Typography>
          {showIcons &&
            loggedInStaff &&
            loggedInStaff.permissions.includes(PERMISSION.EDIT_LINKS) && (
              <>
                <EditIcon
                  sx={{ fontSize: 18, cursor: "pointer", ":hover": { color: "#26C6F5" } }}
                  onClick={handleEditClick}
                />
                <DeleteIcon
                  sx={{ fontSize: 18, cursor: "pointer", ":hover": { color: "#C2310C" } }}
                  onClick={handleDeleteClick}
                />
              </>
            )}
        </Box>
        <Divider />
        <Grid container spacing={2}>
          {links &&
            links.map((link) => (
              <LinkCard
                key={link.id}
                link={link}
                setLinkOpen={setLinkOpen}
                setLinkToDeleteId={setLinkToDeleteId}
                setDeleteLinkOpen={setDeleteLinkOpen}
              />
            ))}
        </Grid>
      </Box>
    </>
  );
}

export default Section;
