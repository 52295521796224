import { useState } from "react";
import { Card, CardContent, Grid, Link, Typography, CardActions } from "@mui/material";
import { LinkRecord } from "../../interfaces/interfaces";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { linkFormAtom, loggedInStaffAtom } from "../../recoil/atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { PERMISSION } from "../../libraries/objects";

type Props = {
  link: LinkRecord;
  setLinkOpen: (prop: boolean) => void;
  setLinkToDeleteId: (prop: string | null) => void;
  setDeleteLinkOpen: (prop: boolean) => void;
};

export default function LinkCard({
  link,
  setLinkOpen,
  setLinkToDeleteId,
  setDeleteLinkOpen,
}: Props) {
  const [showIcons, setShowIcons] = useState(false);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const setLinkForm = useSetRecoilState(linkFormAtom);

  const formattedLink = link.link.slice(0, 4) === "http" ? link.link : `http://${link.link}`;

  const handleMouseEnter = () => {
    setShowIcons(true);
  };

  const handleMouseLeave = () => {
    setShowIcons(false);
  };

  const handleEditClick = () => {
    setLinkForm(link);
    setLinkOpen(true);
  };

  const handleDeleteClick = () => {
    setLinkToDeleteId(link.id);
    setDeleteLinkOpen(true);
  };

  return (
    <Grid item xs={6} sm={3} md={2}>
      <Card
        sx={{ mt: 1, ":hover": { boxShadow: 4 }, height: 90 }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Link
          underline="none"
          sx={{ textDecoration: "none", cursor: "pointer", color: "#333" }}
          href={formattedLink}
          target="_blank"
        >
          <CardContent
            sx={{
              textAlign: "center",
              position: "relative",
              pt: 1,
              pr: 1,
              pl: 1,
              pb: 0,
              height: 35,
              overflow: "scroll",
            }}
          >
            <Typography>{link.name}</Typography>
          </CardContent>
        </Link>
        {loggedInStaff && loggedInStaff.permissions.includes(PERMISSION.EDIT_LINKS) && (
          <CardActions sx={{ position: "relative", mt: 1 }}>
            {" "}
            {showIcons && (
              <>
                {" "}
                <EditIcon
                  sx={{
                    fontSize: 16,
                    position: "absolute",
                    right: 32,
                    cursor: "pointer",
                    bottom: 4,
                    ":hover": { color: "#26C6F5" },
                  }}
                  onClick={handleEditClick}
                />
                <DeleteIcon
                  sx={{
                    fontSize: 16,
                    position: "absolute",
                    right: 8,
                    cursor: "pointer",
                    bottom: 4,
                    ":hover": { color: "#C2310C" },
                  }}
                  onClick={handleDeleteClick}
                />
              </>
            )}
          </CardActions>
        )}
      </Card>
    </Grid>
  );
}
