import React from "react";
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  Button,
} from "@mui/material";
import useAddDoc from "../../hooks/useAddDoc";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import { sectionFormAtom, sectionsResetAtom } from "../../recoil/atoms";
import { useRecoilState, useSetRecoilState } from "recoil";
import { BLANK_SECTION_FORM } from "../../libraries/objects";

type Props = {
  open: boolean;
  setOpen: (newValue: boolean) => void;
};

const ManageSectionDialog = ({ open, setOpen }: Props) => {
  const { sendRequest: addDoc } = useAddDoc();
  const { sendRequest: updateDoc } = useUpdateDoc();
  const [sectionForm, setSectionForm] = useRecoilState(sectionFormAtom);
  const setSectionsReset = useSetRecoilState(sectionsResetAtom);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSectionForm((prevValue) => {
      return { ...prevValue, [event.target.name]: event.target.value };
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    "id" in sectionForm
      ? await updateDoc({ col: "sections", data: sectionForm, id: sectionForm.id })
      : await addDoc({ col: "sections", data: sectionForm });
    setSectionForm(BLANK_SECTION_FORM);
    setOpen(false);
    setSectionsReset((prevValue) => !prevValue);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs">
      <DialogTitle sx={{ fontSize: 44 }}>New Section</DialogTitle>
      <DialogContent>
        <DialogContentText>Order:</DialogContentText>
        <TextField
          autoFocus
          name="order"
          type="number"
          required
          fullWidth
          value={sectionForm.order}
          variant="outlined"
          onChange={handleChange}
        />
        <DialogContentText>Section Name:</DialogContentText>
        <TextField
          autoFocus
          name="name"
          type="text"
          required
          fullWidth
          value={sectionForm.name}
          variant="outlined"
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>Submit</Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(ManageSectionDialog);
