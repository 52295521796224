import { Link } from "react-router-dom";
import { loggedInStaffAtom } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";
import { PERMISSION } from "../../libraries/objects";

function Weblinks() {
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);

  return (
    <>
      {loggedInStaff && loggedInStaff.permissions.includes(PERMISSION.EDIT_STAFF) && (
        <>
          <Link className="webLink" to="/staff">
            Staff
          </Link>
          <Link className="webLink" to="/">
            Hub
          </Link>
        </>
      )}
    </>
  );
}

export default Weblinks;
