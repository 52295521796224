import { StaffRecord, LinkRecord, SectionRecord } from "../interfaces/interfaces";

export const parseStaffResponse = (response: StaffRecord[]): StaffRecord[] =>
  response.map((staff: StaffRecord) => ({
    id: staff?.id ?? "",
    firstName: staff?.firstName ?? "",
    lastName: staff?.lastName ?? "",
    email: staff?.email ?? "",
    permissions: staff?.permissions ?? [],
    homerooms: staff?.homerooms ?? [],
    createdAt: staff?.createdAt ?? null,
  }));

export const parseLinkResponse = (response: LinkRecord[]): LinkRecord[] =>
  response.map((link: LinkRecord) => ({
    id: link?.id ?? "",
    name: link?.name ?? "",
    link: link?.link ?? "",
    createdAt: link?.createdAt ?? null,
    sectionId: link?.sectionId ?? "",
  }));

export const parseSectionResponse = (response: SectionRecord[]): SectionRecord[] =>
  response.map((section: SectionRecord) => ({
    id: section?.id ?? "",
    order: section?.order ?? "",
    name: section?.name ?? "",
    createdAt: section?.createdAt ?? null,
  }));
