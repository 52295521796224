import React from "react";
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";
import { sectionsAtom, linkFormAtom, linksResetAtom } from "../../recoil/atoms";
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  Button,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import useAddDoc from "../../hooks/useAddDoc";
import { BLANK_LINK_FORM } from "../../libraries/objects";
import useUpdateDoc from "../../hooks/useUpdateDoc";

type Props = {
  open: boolean;
  setOpen: (newValue: boolean) => void;
};

const ManageLinkDialog = ({ open, setOpen }: Props) => {
  const { sendRequest: addDoc } = useAddDoc();
  const [linkForm, setLinkForm] = useRecoilState(linkFormAtom);
  const sections = useRecoilValue(sectionsAtom);
  const { sendRequest: updateDoc } = useUpdateDoc();
  const setLinksReset = useSetRecoilState(linksResetAtom);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLinkForm((prevValue) => {
      return { ...prevValue, [event.target.name]: event.target.value };
    });
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setLinkForm((prevValue) => {
      return { ...prevValue, [event.target.name]: event.target.value };
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    "id" in linkForm
      ? await updateDoc({ col: "links", data: linkForm, id: linkForm.id })
      : await addDoc({ col: "links", data: linkForm });
    setLinkForm(BLANK_LINK_FORM);
    setOpen(false);
    setLinksReset((prevValue) => !prevValue);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle sx={{ fontSize: 44 }}>Add Link</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mt: 1 }}>Link Name:</DialogContentText>
        <TextField
          autoFocus
          name="name"
          type="text"
          required
          fullWidth
          value={linkForm.name}
          variant="outlined"
          onChange={handleChange}
        />
        <DialogContentText>Link:</DialogContentText>
        <TextField
          name="link"
          type="text"
          required
          fullWidth
          value={linkForm.link}
          variant="outlined"
          onChange={handleChange}
        />
        <DialogContentText sx={{ mt: 1 }}>Link Section:</DialogContentText>
        <Select value={linkForm.sectionId} onChange={handleSelectChange} fullWidth name="sectionId">
          {sections &&
            sections.map((section) => (
              <MenuItem key={section.id} value={section.id}>
                {section.name}
              </MenuItem>
            ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>Submit</Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(ManageLinkDialog);
