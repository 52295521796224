import { atom } from "recoil";
import {
  StaffRecord,
  StaffFromAPIInterface,
  SectionRecord,
  LinkRecord,
  Link,
  Section,
} from "../interfaces/interfaces";
import { BLANK_LINK_FORM, BLANK_SECTION_FORM } from "../libraries/objects";

export const staffAtom = atom<StaffRecord[]>({
  key: "staff",
  default: [],
});

export const loggedInStaffAtom = atom<StaffRecord | null>({
  key: "loggedInStaff",
  default: null,
});

export const staffResetAtom = atom({
  key: "staffReset",
  default: false,
});

export const staffFromAPIAtom = atom<StaffFromAPIInterface[]>({
  key: "staffFromAPI",
  default: [],
});

export const sectionsAtom = atom<SectionRecord[]>({
  key: "sections",
  default: [],
});

export const linksAtom = atom<LinkRecord[]>({
  key: "links",
  default: [],
});

export const linkFormAtom = atom<Link | LinkRecord>({
  key: "linkForm",
  default: BLANK_LINK_FORM,
});

export const sectionFormAtom = atom<Section | SectionRecord>({
  key: "sectionForm",
  default: BLANK_SECTION_FORM,
});

export const linksResetAtom = atom({
  key: "linksReset",
  default: false,
});

export const sectionsResetAtom = atom({
  key: "sectionsReset",
  default: false,
});
