import { useState, useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  linkFormAtom,
  linksResetAtom,
  sectionsAtom,
  sectionFormAtom,
  sectionsResetAtom,
} from "../../recoil/atoms";
import { Button, Typography } from "@mui/material";
import ManageLinkDialog from "./ManageLinkDialog";
import ManageSectionDialog from "./ManageSectionDialog";
import Section from "./Section";
import { BLANK_LINK_FORM, BLANK_SECTION_FORM } from "../../libraries/objects";
import DeleteDialog from "../shared/DeleteDialog";

const StaffHubContainer = () => {
  const [linkOpen, setLinkOpen] = useState(false);
  const [sectionOpen, setSectionOpen] = useState(false);
  const [deleteLinkOpen, setDeleteLinkOpen] = useState(false);
  const [deleteSectionOpen, setDeleteSectionOpen] = useState(false);
  const sections = useRecoilValue(sectionsAtom);
  const setLinkForm = useSetRecoilState(linkFormAtom);
  const setSectionForm = useSetRecoilState(sectionFormAtom);
  const setLinksReset = useSetRecoilState(linksResetAtom);
  const setSectionsReset = useSetRecoilState(sectionsResetAtom);
  const [linkToDeleteId, setLinkToDeleteId] = useState<string | null>(null);
  const [sectionToDeleteId, setSectionToDeleteId] = useState<string | null>(null);

  const handleManageLink = useCallback(() => {
    setLinkForm(BLANK_LINK_FORM);
    setLinkOpen(true);
  }, [setLinkForm]);

  const handleManageSection = useCallback(() => {
    setSectionForm(BLANK_SECTION_FORM);
    setSectionOpen(true);
  }, [setSectionForm]);

  return (
    <>
      <Typography variant="h2" sx={{ mt: 3 }}>
        Staff Hub
      </Typography>
      <Button sx={{ margin: 1 }} variant="contained" onClick={handleManageSection}>
        New Section
      </Button>
      <Button sx={{ margin: 1 }} variant="contained" onClick={handleManageLink}>
        New Link
      </Button>
      {sections &&
        sections.map((section) => (
          <Section
            key={section.id}
            section={section}
            setSectionOpen={setSectionOpen}
            setLinkOpen={setLinkOpen}
            setDeleteLinkOpen={setDeleteLinkOpen}
            setDeleteSectionOpen={setDeleteSectionOpen}
            setLinkToDeleteId={setLinkToDeleteId}
            setSectionToDeleteId={setSectionToDeleteId}
          />
        ))}
      <ManageLinkDialog open={linkOpen} setOpen={setLinkOpen} />
      <ManageSectionDialog open={sectionOpen} setOpen={setSectionOpen} />
      <DeleteDialog
        open={deleteLinkOpen}
        setOpen={setDeleteLinkOpen}
        message="Are you sure you want to delete this link?"
        setReset={setLinksReset}
        collection="links"
        id={linkToDeleteId}
      />
      <DeleteDialog
        open={deleteSectionOpen}
        setOpen={setDeleteSectionOpen}
        message="Are you sure you want to delete this section?"
        setReset={setSectionsReset}
        collection="sections"
        id={sectionToDeleteId}
      />
    </>
  );
};

export default StaffHubContainer;
